<script setup lang="ts">
const route = useRoute()
const { data: userData, status: userStatus } = await useHyc(
  '/api/v1/user/data',
  {
    key: 'userInfo',
    // dedupe: 'defer',
    // dedupe: 'cancel',
    // lazy: true,
    // server: false,
  },
)
const isLogin = computed(() => (userData.value ? true : false))
const l2 = computed(() => {
  return route.path.endsWith('/')
    ? route.path.split('/').length > 3
    : route.path.split('/').length > 2
})
//swipe
const swipMenu = ref(false)
const pageEl = ref<HTMLElement | null>(null)
const containerWidth = computed(() => pageEl.value?.offsetWidth)

const { isSwiping, direction, lengthX } = useSwipe(pageEl, {
  passive: true,
  onSwipeEnd: (e, direction) => {
    if (
      lengthX.value < 0 &&
      containerWidth.value &&
      Math.abs(lengthX.value) / containerWidth.value >= 0.5
    ) {
      swipMenu.value = true
      setTimeout(() => {
        swipMenu.value = false
      }, 10000)
    }
    if (
      direction == 'left' &&
      containerWidth.value &&
      Math.abs(lengthX.value) / containerWidth.value >= 0.35
    ) {
      swipMenu.value = false
    }
  },
})
</script>
<template>
  <div
    class="from-primary-200 to-primary-100 dark:from-primary-900 dark:to-primary-800 fixed h-dvh w-full touch-pan-y overflow-y-auto bg-gradient-to-tr pb-3 pt-10 sm:pt-20 dark:bg-gradient-to-t"
  >
    <ClientOnly>
      <!-- localStore -->
      <ClientBG />
    </ClientOnly>
    <div ref="pageEl" class="relative overflow-x-hidden">
      <NuxtClientFallback>
        <div class="inline max-w-full overflow-x-auto px-3 sm:hidden">
          <TdBreadcrumb :key="route.path + '-tbrc'" v-if="isLogin && l2" />
        </div>
        <slot />
        <UContainer class="flex justify-center">
          <span
            class="inset-y-4 bottom-0 font-sans font-light antialiased opacity-20"
            >Build by yikou Caomei Baofan</span
          >
        </UContainer>
      </NuxtClientFallback>
    </div>
    <div
      id="bar"
      :class="[
        isLogin ? 'backdrop-blur' : 'backdrop-blur-none',
        'fixed top-0 z-30 flex w-full items-center justify-between px-2 shadow-sm transition-all',
      ]"
    >
      <NuxtClientFallback>
        <div class="flex shrink-0 items-center">
          <ULink
            id="logo"
            class="flex shrink-0 items-center"
            :to="{ name: 'index' }"
          >
            <nuxt-img
              preset="logo"
              class="size-10 transition-all ~m-2/4"
              src="/orca.png"
            />
            <span class="hidden pr-2 text-xl font-bold sm:block">{{
              // $t('hddolby')
              'HD Dolby'
            }}</span>
          </ULink>
          <!--          <TopBreadcrumb v-if="isLogin && l2" class="hidden sm:inline" />-->
          <div class="hidden sm:inline px-2">
            <TdBreadcrumb v-if="isLogin && l2" />
          </div>
        </div>
        <UserBar v-if="$route.name != 'login' && $route.name != 'index'" />
        <div v-else class="flex shrink">
          <UButton
            v-if="$route.name == 'login'"
            :to="{ name: 'index' }"
            label="Index"
            class="m-4 flex items-center text-center shadow-lg transition-all hover:shadow-md active:shadow-none"
            leading-icon="material-symbols:arrow-left-alt-rounded"
          />

          <UButton
            v-if="
              (!isLogin || userStatus != 'success') && $route.name == 'index'
            "
            :to="{ name: 'login' }"
            class="m-4 flex items-center text-center shadow-lg transition-all hover:shadow-md active:shadow-none"
            label="Log in"
            trailing-icon="ic:round-login"
          />

          <UButton
            v-if="isLogin && $route.name == 'index'"
            icon="i-heroicons-home"
            :to="{ name: 'home' }"
            class="m-4 flex items-center text-center shadow-lg transition-all hover:shadow-md active:scale-90 active:shadow-none"
            label="home"
          />
        </div>
      </NuxtClientFallback>
    </div>

    <div
      v-if="isLogin && $route.name != 'login'"
      :class="[
        swipMenu
          ? 'translate-x-0'
          : l2
            ? '-translate-x-[98%] hover:translate-x-0'
            : '-translate-x-[95%] hover:translate-x-0',
        'fixed left-0 top-20 z-30 h-fit transition-all',
      ]"
    >
      <!-- <NuxtClientFallback> -->
      <VerticalNavigation />
      <!-- </NuxtClientFallback> -->
    </div>

    <ClientOnly>
      <!-- <NuxtClientFallback> -->
      <UserId
        v-if="isLogin && $route.name != 'login' && $route.name != 'index'"
      />
    </ClientOnly>
    <LoginOverlayView v-if="$route.name != 'index'" />
    <!-- </NuxtClientFallback> -->
  </div>
</template>
