
import * as myProviderRuntime$faKW7CTypN from '/opt/buildhome/repo/providers/tmdb-provider.ts'
import * as noneRuntime$AlJCu1LSrU from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "cover": {
      "formate": [
        "heif",
        "avif",
        "webp"
      ],
      "modifiers": {
        "fit": "cover",
        "format": "heif",
        "width": 300,
        "height": 300
      }
    },
    "poster": {
      "formate": [
        "heif",
        "avif",
        "webp"
      ],
      "modifiers": {
        "fit": "cover",
        "format": "heif",
        "width": 2000,
        "height": 3000
      }
    },
    "avatar": {
      "formate": [
        "heif",
        "avif",
        "webp"
      ],
      "modifiers": {
        "format": "heif",
        "width": 75,
        "height": 75
      }
    },
    "logo": {
      "formate": [
        "heif",
        "avif",
        "webp"
      ],
      "modifiers": {
        "format": "heif",
        "width": 100,
        "height": 100
      }
    }
  },
  "provider": "none",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['tmdbProvider']: { provider: myProviderRuntime$faKW7CTypN, defaults: {"baseURL":"https://image.tmdb.org"} },
  ['none']: { provider: noneRuntime$AlJCu1LSrU, defaults: {} }
}
        