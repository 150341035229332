<script setup lang="ts">
const { data: userInfo, status: userStatus } = await useHyc(
  '/api/v1/user/data',
  {
    dedupe: 'defer',
    key: 'userInfo',
    lazy: false,
  },
)

const isLogin = computed(() => userStatus.value == 'success')
</script>
<template>
  <div class="bg-primary-50 dark:bg-primary-900 relative min-h-dvh pb-3 pt-16">
    <div
      id="bar"
      class="fixed top-0 flex w-full items-center justify-between backdrop-blur-lg"
    >
      <span class="flex items-center">
        <nuxt-img preset="logo" class="m-4 size-10" src="/orca.png" />
        <span class="text-xl font-bold">HDDolby</span>
      </span>
      <u-button
        v-if="isLogin"
        :to="{ name: 'home' }"
        class="m-4 flex items-center text-center shadow-lg transition-all hover:shadow-md active:shadow-none"
        >home
        <Icon name="material-symbols:arrow-right-alt-rounded" />
      </u-button>
      <u-button
        v-else
        :to="{ name: 'login' }"
        class="m-4 shadow-lg transition-all hover:shadow-md active:shadow-none"
        >Log in

        <Icon name="ic:round-login" />
      </u-button>
    </div>
    <slot />
  </div>
</template>
