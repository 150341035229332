<script setup lang="ts">
// const route = useRoute()
const { t } = useI18n()
const links = computed(() => [
  [
    {
      label: t('Home'),
      icon: 'i-heroicons-home',
      to: { name: 'home' }
    },
    {
      label: t('Forums'),
      to: 'http://forums.orcinusorca.org',
      icon: 'logos:flarum',
      //   to: `${route.path.startsWith('/dev') ? '/dev' : ''}/components/horizontal-navigation`
      target: '_blank'
    },
    {
      label: 'Torrents',
      icon: 'arcticons:libretorrent',
      to: {
        name: 'torrent'
        // query: { page: 1 } ,
      }
    },
    { label: 'Offers', icon: 'mdi:offer', to: { name: 'offers' } },
    { label: 'Requests', icon: 'ic:round-request-quote' },
    { label: 'Upload', icon: 'ic:twotone-upload-file', to: { name: 'upload' } },
    {
      label: 'Subtitles',
      icon: 'fluent:subtitles-24-regular'
    }
  ],
  [
    {
      label: 'Bonus Store',
      icon: 'ph:shopping-cart-light',
      to: { name: 'mybonus' }
    }
  ],
  [
    {
      label: t('User CP'),
      to: { name: 'usercp' },
      icon: 'i-heroicons-command-line',
      badge: 100
    },
    { label: 'Top 10', icon: 'i-heroicons-chart-bar', badge: 100 }
  ],
  [
    {
      label: 'Rules and FAQ',
      to: 'https://wiki.orcinusorca.org',
      icon: 'solar:question-square-bold',
      target: '_blank'
    },
    {
      label: 'Staff',
      to: { name: 'contactstaff' },
      icon: 'ic:twotone-support-agent'
    }
  ]
])
const entertainmentLinks = [
  {
    label: 'Lucky Draw',
    icon: 'game-icons:rolling-dices',
    to: { name: 'luckydraw' }
  },
  { label: 'Medals', icon: 'emojione-v1:military-medal' },
  { label: 'Blackjack', icon: 'game-icons:poker-hand' }
]
</script>

<template>
  <UVerticalNavigation
    :links="links"
    class="overflow-visable bg-primary-200/50 dark:bg-primary-900/50 rounded-r-lg p-2 ring-1 backdrop-blur transition-all hover:p-3 hover:shadow-2xl"
    :ui="{
      base: ' transition-all  ',
      active: ' translate-x-10 shadow-xl ring-2  ',
      inactive:
        ' hover:translate-x-4 hover:shadow-xl active:border-2 active:border-primary-500  ',
      icon: {
        active: 'hidden',
      },
    }"
  >
    <template #badge="{ link, isActive }">
      <div v-if="isActive" class="relative flex flex-1 justify-end">
        <UIcon v-if="link.icon" class="text-base" :name="link.icon" />
      </div>
    </template>
    <template #default="{ link, isActive }">
      <span
        v-if="link.to && link.to.name == 'mybonus'"
        class="relative flex items-center gap-1"
      >
        {{ link.label }}
        <UPopover :popper="{ placement: 'right' }" mode="hover">
          <UIcon
            class="leading-3 opacity-0 transition-all group-hover:opacity-100"
            name="ic:twotone-expand-circle-down"
          />
          <template #panel>
            <div class="p-2">
              <UVerticalNavigation :links="entertainmentLinks" />
            </div>
          </template>
        </UPopover>
      </span>
    </template>
  </UVerticalNavigation>
</template>
