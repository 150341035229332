<script setup lang="ts">
definePageMeta({
  // layout: 'landing',
  middleware: undefined,
})

const isLogin = useisLogin()
</script>
<template>
  <div class=" ">
    <div class="relative">
      index
      <ClientOnly>
        isPWAInstalled : {{ $pwa?.isPWAInstalled }} offlineReady :
        {{ $pwa?.offlineReady }}
      </ClientOnly>
      isLogin:{{ isLogin }}
      
      <p class="motion-preset-fade ~md/lg:~text-base/4xl">index</p>
      <p class="motion-preset-fade text-9xl motion-delay-[700ms]">index</p>
    </div>
  </div>
</template>
