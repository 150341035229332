<script setup lang="ts">
import { formatBytes } from '~/utils/userInfo'

const {
  data: userInfo,
  status: infoStatus,
  refresh: reUserInfo,
  clear: clearUserInfo,
} = await useHyc('/api/v1/user/data', {
  key: 'userInfo',
  // dedupe: 'cancel',
  dedupe: 'defer',
  lazy: true,
})
const { $hyc } = useNuxtApp()
const loginCookie = useisLogin()
const submitLogout = async () => {
  try {
    const out = await $hyc('/api/v1/user/logout', { method: 'GET' })
    setTimeout(() => {
      clearUserInfo()
      loginCookie.value = false
    }, 50)
    navigateTo({ name: 'index' })
  } catch (e) {
    console.log(e)
  }
}
const handleLogout = () => {
  submitLogout()
}
const setBadge = async () => {
  await reUserInfo()
  navigator.setAppBadge(userInfo.value?.data?.seed_bonus).catch((error) => {
    //Do something with the error.
    console.error(error)
  })
}
const focused = useWindowFocus()
watchThrottled(
  focused,
  (v, ov) => {
    if (v && !ov) {
      reUserInfo()
    }
  },
  { throttle: 500000 },
)
watch(infoStatus, (v, ov) => {
  if (v === 'error') {
    loginCookie.value = false
  }
})
const avatar = computed(() =>
  decodeURIComponent(userInfo?.value?.data?.avatar || ''),
)
//hover on user avatar
const hoverState = ref(false)
</script>
<template>
  <div
    :class="[
      userInfo && infoStatus != 'error'
        ? 'opacity-100'
        : 'translate-x-3/4 opacity-5',
      'bg-primary-300/30 my-1 flex shrink snap-x snap-mandatory overflow-x-auto overflow-y-visible scroll-smooth rounded-lg px-4 py-2 shadow-inner ring-2 ring-white/20 backdrop-blur-md transition-all duration-500 scrollbar-hide',
      'motion-scale-x-in-[30%] motion-scale-y-in-90 motion-ease-spring-bouncy',
    ]"
  >
    <div
      class="motion-timing-spring-smooth motion-preset-focus flex gap-3 motion-duration-500 motion-delay-200"
    >
      <UChip
        :color="
          userInfo
            ? userInfo?.data?.status == 'pending'
              ? 'amber'
              : 'green'
            : 'amber'
        "
        size="md"
      >
        <div
          class="bg-primary-50/10 dark:bg-primary-900/50 flex snap-start items-center rounded-md shadow backdrop-blur backdrop-saturate-50"
        >
          <HoverCardRoot v-model:open="hoverState">
            <HoverCardTrigger
              class="-m-1 cursor-pointer"
              target="_blank"
              rel="noreferrer noopener"
            >
              <NuxtImg
                class="size-10 snap-end rounded-md bg-white from-black to-zinc-800 object-cover object-center shadow-inner ring-1 dark:bg-gradient-to-t"
                :src="avatar"
                :placeholder="'https://img.ptdream.net/o/ptdream-img/2024/08/14/IMG_5771.jpeg'"
              />
            </HoverCardTrigger>
            <HoverCardPortal>
              <HoverCardContent
                class="data-[side=bottom]:animate-slideUpAndFade data-[side=right]:animate-slideLeftAndFade data-[side=left]:animate-slideRightAndFade data-[side=top]:animate-slideDownAndFade w-[300px] rounded-md bg-white p-5 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] data-[state=open]:transition-all"
                :side-offset="5"
              >
                {{ userInfo }}
                <HoverCardArrow class="fill-white" :width="8" />
              </HoverCardContent>
            </HoverCardPortal>
          </HoverCardRoot>
          <span class="px-3">
            {{ userInfo ? userInfo?.data?.name : 'hyc' }}
            (UID:
            <span class="font-black">
              {{ userInfo ? userInfo?.data?.id : '6677' }}
            </span>
            )
          </span>
        </div>
      </UChip>
      <UButton
        variant="outline"
        class="snap-end"
        icon="i-ph:sign-out-bold"
        label="Logout"
        @click="handleLogout"
      />
      <UButton
        variant="outline"
        class="snap-end"
        icon="ion:bookmarks"
        label="Bookmarks"
        @click="handleLogout"
      />
      <UButton
        variant="outline"
        class="snap-end"
        icon="majesticons:rss"
        label="RSS"
        @click="handleLogout"
      />
      <div
        class="bg-primary-50/10 dark:bg-primary-900/50 flex snap-end items-center gap-1 rounded-md px-2 shadow backdrop-blur backdrop-saturate-50"
      >
        <UBadge color="primary" variant="subtle">
          <UIcon name="i-clarity:share-line" />
          {{
            userInfo?.data?.upload_volume && userInfo?.data?.download_volume
              ? userInfo?.data?.upload_volume / userInfo?.data?.download_volume
                ? (
                    userInfo?.data?.upload_volume /
                    userInfo?.data?.download_volume
                  ).toFixed(2)
                : 0
              : 0
          }}</UBadge
        >
        
        <UBadge color="green" variant="subtle">
          <UIcon name="i-mdi:progress-upload" />
          {{ userInfo ? formatBytes(userInfo?.data?.upload_volume ?? -1).join('') : 0 }}</UBadge
        >
        <UBadge color="red" variant="solid">
          <UIcon name="i-material-symbols:download-for-offline" />
          {{ userInfo ? formatBytes(userInfo?.data?.download_volume ?? -1).join('') : 0 }}</UBadge
        >
        <UBadge color="primary" variant="subtle">
          <UIcon name="i-material-symbols:more-time-rounded" />
          {{ userInfo ? userInfo?.data?.seed_time : 0 }}</UBadge
        >
        <UBadge color="primary" variant="solid">
          <UIcon name="i-mdi:sort-clock-ascending-outline" />
          {{ userInfo ? userInfo?.data?.leech_time : 0 }}</UBadge
        >
        <UBadge color="primary" class="text-nowrap" variant="solid">
          Seed Bonus: {{ userInfo ? userInfo?.data?.seed_bonus : 0 }}</UBadge
        >
        <UBadge color="primary" class="text-nowrap" variant="solid">
          Bonus
          <button class="rounded-sm bg-white/20 px-1 hover:underline">
            Use</button
          >: {{ userInfo ? userInfo?.data?.seed_bonus : 0 }}</UBadge
        >
        <UBadge color="primary" class="text-nowrap" variant="solid">
          Invite
          <button class="rounded-sm bg-white/20 px-1 hover:underline">
            Send</button
          >: 0/0</UBadge
        >
        <UBadge color="primary" class="text-nowrap" variant="solid">
          <button class="rounded-sm bg-white/20 px-1 hover:underline">
            H&R</button
          >: 0</UBadge
        >
        <UBadge class="text-nowrap" variant="soft">
          Active Torrents:
          <UIcon name="line-md:uploading-loop" class="text-green-500" />
          0
          <UIcon
            name="i-material-symbols:download-for-offline"
            class="text-red-500"
          />
          0
        </UBadge>
        <UBadge class="text-nowrap"> Slots: Unlimited </UBadge>
        <UBadge class="text-nowrap" variant="soft">
          流量排名：
          <UIcon name="i-mdi:progress-upload" class="text-green-500" />
          0/
          <UIcon
            name="i-material-symbols:download-for-offline"
            class="text-red-500"
          />
          0
        </UBadge>
      </div>
<!--      <UButton variant="solid" class="snap-end" icon="iconoir:user-badge-check">-->
<!--        Attend today-->
<!--      </UButton>-->
      <UButton variant="solid" class="snap-end" color="red">
        Donate Us
      </UButton>
    </div>
  </div>

  <!-- </div> -->
</template>
