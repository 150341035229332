<script setup lang="ts">
import type { BreadcrumbProps } from 'tdesign-vue-next'

const route = useRoute()
const id = Number(route.params.id || 1)
const { data: torrentData, refresh: reTorrent } = await useHyc('/api/v1/torrent/{torrent}', {
  key: 'torrent-' + id,
  path: { torrent: id },
  // cache: 'reload',
  lazy: true,
  // immediate: false
})
const options = computed<BreadcrumbProps['options']>(() => {
  const patharr = route.path.split('/')
  patharr.shift()
  if (route.params.id) {
    return patharr.map((v, i, a) => {
      if (i === 1) {
        return {
          content: torrentData.value ? torrentData.value.data!.name : '',
          to: '/' + a.slice(0, i + 1).join('/')
        }
      } else {
        return {
          content: v,
          to: '/' + a.slice(0, i + 1).join('/')
        }
      }
    })
  }
  return patharr.map((v, i, a) => {
    return {
      content: v,
      to: '/' + a.slice(0, i + 1).join('/')
    }
  })
})
</script>

<template>
  <t-breadcrumb max-item-width="130" :options="options" />
</template>
