export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'zh',
  globalInjection: true,
  fallbackLocale: {
    'zh-Hant': ['zh'], // 兼容 zh_TW
    default: ['en', 'zh'],
  },
  useCookie: true,
  messages: {
    en: {
      welcome: 'Welcome',
    },
    zh: {
      welcome: '欢迎',
    },
  },
}))
