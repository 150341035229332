
// @ts-nocheck


export const localeCodes =  [
  "en",
  "zh"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/locales/CPtracker-en_US.json", load: () => import("../i18n/locales/CPtracker-en_US.json" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_CPtracker_en_US_json" */), cache: true },
{ key: "../i18n/locales/detail-en_US.json", load: () => import("../i18n/locales/detail-en_US.json" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_detail_en_US_json" */), cache: true },
{ key: "../i18n/locales/form-en_US.json", load: () => import("../i18n/locales/form-en_US.json" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_form_en_US_json" */), cache: true },
{ key: "../i18n/locales/home-en_US.json", load: () => import("../i18n/locales/home-en_US.json" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_home_en_US_json" */), cache: true },
{ key: "../i18n/locales/login-en_US.json", load: () => import("../i18n/locales/login-en_US.json" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_login_en_US_json" */), cache: true },
{ key: "../i18n/locales/menu-en_US.json", load: () => import("../i18n/locales/menu-en_US.json" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_menu_en_US_json" */), cache: true }],
  "zh": [{ key: "../i18n/locales/CPtracker-zh_CN.ts", load: () => import("../i18n/locales/CPtracker-zh_CN.ts?hash=c5d48503&locale=zh" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_CPtracker_zh_CN_ts" */), cache: false },
{ key: "../i18n/locales/detail-zh_CN.ts", load: () => import("../i18n/locales/detail-zh_CN.ts?hash=ffee2fc5&locale=zh" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_detail_zh_CN_ts" */), cache: false },
{ key: "../i18n/locales/home-zh_CN.ts", load: () => import("../i18n/locales/home-zh_CN.ts?hash=4c243b48&locale=zh" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_home_zh_CN_ts" */), cache: false },
{ key: "../i18n/locales/login-zh_CN.ts", load: () => import("../i18n/locales/login-zh_CN.ts?hash=2557e4be&locale=zh" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_login_zh_CN_ts" */), cache: false },
{ key: "../i18n/locales/menu-zh_CN.ts", load: () => import("../i18n/locales/menu-zh_CN.ts?hash=3bacd29c&locale=zh" /* webpackChunkName: "locale__opt_buildhome_repo_i18n_locales_menu_zh_CN_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "__i18n_config_ts_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "/opt/buildhome/repo/i18n/locales/CPtracker-en_US.json",
        "/opt/buildhome/repo/i18n/locales/detail-en_US.json",
        "/opt/buildhome/repo/i18n/locales/form-en_US.json",
        "/opt/buildhome/repo/i18n/locales/home-en_US.json",
        "/opt/buildhome/repo/i18n/locales/login-en_US.json",
        "/opt/buildhome/repo/i18n/locales/menu-en_US.json"
      ]
    },
    {
      "code": "zh",
      "name": "简体中文",
      "files": [
        "/opt/buildhome/repo/i18n/locales/CPtracker-zh_CN.ts",
        "/opt/buildhome/repo/i18n/locales/detail-zh_CN.ts",
        "/opt/buildhome/repo/i18n/locales/home-zh_CN.ts",
        "/opt/buildhome/repo/i18n/locales/login-zh_CN.ts",
        "/opt/buildhome/repo/i18n/locales/menu-zh_CN.ts"
      ]
    }
  ],
  "defaultLocale": "zh",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://dv.orcinusorca.org/",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/opt/buildhome/repo/i18n/locales/CPtracker-en_US.json"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/detail-en_US.json"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/form-en_US.json"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/home-en_US.json"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/login-en_US.json"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/menu-en_US.json"
      }
    ]
  },
  {
    "code": "zh",
    "name": "简体中文",
    "files": [
      {
        "path": "/opt/buildhome/repo/i18n/locales/CPtracker-zh_CN.ts"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/detail-zh_CN.ts"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/home-zh_CN.ts"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/login-zh_CN.ts"
      },
      {
        "path": "/opt/buildhome/repo/i18n/locales/menu-zh_CN.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
