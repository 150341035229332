export const base64ToBytes = (base64: string) => {
  const binString = atob(base64)
  return Uint8Array.from(binString, (m) => m.codePointAt(0))
}
export const base64ToText = (base64?: string) => {
  return new TextDecoder().decode(
    base64ToBytes(base64 || '8J+QseermfCfjLbvuI7wn5CUCg=='),
  )
}

export const formatBytes = (Bytes: number, decimals: number = 0 ) => {
  if (Bytes == 0) return ['0', '']

  const k = 1024,
    dm = decimals + 1 || 1,
    sizes = ['B','KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(Bytes) / Math.log(k))

  return [
    parseFloat((Bytes / Math.pow(k, i)).toFixed(dm)).toLocaleString(),
    sizes[i],
  ] // parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' +
}
