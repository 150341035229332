export default defineNuxtPlugin({
  enforce: 'pre', // clients will be ready to use by other plugins, Pinia stores etc.
  setup() {
    const clients = useRuntimeConfig().public.openFetch
    const localFetch = useRequestFetch()
    const toast = useToast()
    const isLogin = useisLogin()
    const errorMessage = useErrorMessage() //传递报错消息
    const errorDetails = useErrorDetails() //传递报错消息
    return {
      provide: Object.entries(clients).reduce(
        (acc, [name, options]) => ({
          ...acc,
          //   [name]: createOpenFetch(options, localFetch)

          // or add the logging:
          [name]: createOpenFetch((localOptions) => {
            const csrfCookie: string = 'XSRF-TOKEN'
            const token = useCookie(csrfCookie).value || 'xs'

            // Here we will create a default set of headers for every request
            // if present we will also spread the `headers` set by the user
            // then we will delete them to avoid collision in next spread
            const headers: HeadersInit = {
              Accept: 'application/json',
              'Cache-Control': 'no-cache',
              'X-XSRF-TOKEN': token,
              // ...localOptions.headers,
            }
            return {
              headers,
              retry: 2,
              retryDelay: 200,
              credentials: 'include',
              onResponseError({ response }) {
                if (response._data.status === 10401) {
                  isLogin.value = false
                }
                switch (response.status) {
                  case 401:
                  case 419:
                    isLogin.value = false
                  break
                  // eslint-disable-next-line no-fallthrough
                  case 403:
                  case 400:
                    toast.add({
                      icon: 'i-heroicons-exclamation-circle-16-solid',
                      title: response._data.error.message,
                      description: response._data.error.details,
                    })

                    errorMessage.value = response._data.error.message
                    errorDetails.value = response._data.error.details
                    setTimeout(() => {
                      errorMessage.value = ''
                    }, 1200)
                    throw new Error(
                      `${response._data.error.message},error code: ${response.status}`,
                    )
                    break
                  case 500:
                    toast.add({
                      icon: 'i-heroicons-exclamation-circle-16-solid',
                      title: 'Error',
                      description: '500 error',
                      click: () => {
                        navigateTo({ name: 'index' })
                      },
                    })
                    throw new Error(`error code: ${response.status}`)
                    break
                  default:
                    break
                }
              },
              ...options,
              ...localOptions,
            }
          }, localFetch),
        }),
        {},
      ),
    }
  },
})
