<script lang="ts" setup>
import { useStorage } from '@vueuse/core'
import { UseImage } from '@vueuse/components'
const { isMobile, isAndroid } = useDevice()
const isAndroidMobile = computed(() => {
  return isAndroid && isMobile
})
const liveSource = useStorage('BGsrc-store', '', localStorage)
const reload = ref(false)
const isVideo = computed(() => {
  return (
    liveSource.value.endsWith('.mp4') ||
    liveSource.value.endsWith('.webm') ||
    liveSource.value.endsWith('.mov') ||
    liveSource.value === ''
  )
})

watch(liveSource, (v, ov) => {
  if (v[0] !== ov[0]) {
    reload.value = true
    setTimeout(() => {
      reload.value = false
    }, 20)
  }
})

onMounted(() => {
  reg()
})
const reg = async () => {
  const status = await navigator.permissions.query({
    name: 'periodic-background-sync',
  })
  if (status.state === 'granted') {
    // Periodic background sync can be used.
    const registration = await navigator.serviceWorker.ready
    if ('periodicSync' in registration) {
      try {
        await registration.periodicSync.register('user-state', {
          // An interval of one day.
          minInterval: 60 * 60 * 1000,
        })
        self.addEventListener('periodicsync', (event) => {
          if (event.tag === 'user-state') {
            event.waitUntil(setBadge())
          }
        })
      } catch (error) {
        // Periodic background sync cannot be used.
      }
    }
  } else {
    // Periodic background sync cannot be used.
  }
}
</script>

<template>
  <div
    :class="[
      $route.name == 'login' || $route.name == 'index'
        ? 'opacity-100'
        : $route.name == 'usercp'
          ? 'opacity-90'
          : 'opacity-20',
      'pointer-events-none fixed top-0 h-full w-full select-none overflow-clip',
    ]"
  >
    <LiveBG v-if="isVideo && !isAndroidMobile && !reload" />
    <UseImage
      v-else
      :src="liveSource"
      class="min-h-full min-w-full object-cover object-center"
    >
      <template #loading>
        <img
          class="min-h-full min-w-full object-cover object-center"
          :src="'https://image.tmdb.org/t/p/original/uHkarMeytn0dYLze2OlQ4APrWU7.jpg'"
        >
      </template>
      <template #error>
        <img
          class="min-h-full min-w-full object-cover object-center"
          :src="'https://img.ptdream.net/o/ptdream-img/2024/08/28/backg.jpg'"
        >
      </template>
    </UseImage>
  </div>
</template>
